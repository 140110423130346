import React from 'react';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="loginContainer">
        <div className="loginContainerContent">
          <img
            src="https://atomicloops.com/client/assets/favicon/favicon.ico"
            alt=""
            width={40}
            height={40}
          />
          <br />
          <h1>Welcome to AtomicGPT</h1>
          <p>Your Ultimate AI Assistant</p>
          <div className="loginButtonWrapper">
            <Button text="Log in" handleClick={() => navigate('/login')} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
