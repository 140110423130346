import React, { useContext, useState } from 'react';
import '../signup/signupform.css';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import SvgComponent from '../SvgComponent';
import { endpoints } from '../../utils/config';
import { jwtDecode } from 'jwt-decode';

const SignupForm = () => {
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showLoginPassword, setShowLoginPassword] = useState(false);

  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // api
      e.preventDefault();
      const headers = { 'Content-Type': `application/json` };
      const body = {
        email: loginEmail,
        password: loginPassword,
      };

      const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers,
      };

      const response = await fetch(endpoints.login, requestOptions);
      const json = await response.json();
      if (response.status === 200) {
        var decoded = jwtDecode(json.data.access);
        const userId = decoded.user_id;
        localStorage.setItem('refreshToken', json.data.refresh);
        localStorage.setItem('accessToken', json.data.access);
        localStorage.setItem('userId', userId);
        // user api
        const userResponse = await fetch(endpoints.users + `${userId}/`, {
          method: `GET`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
            'Content-Type': `application/json`,
          },
        });

        const userJson = await userResponse.json();
        if (userResponse.status === 200) {
          // localStorage.setItem('username', userJson.username);
          // localStorage.setItem('profilePicture', userJson.profilePicture);
          navigate('/');
          return;
        }
        setErrorMessage(JSON.stringify(userJson.error));
        return;
      } else {
        setErrorMessage(JSON.stringify(json.error));
      }
    } catch (error) {
      const errorMessage = error.message;
      setErrorMessage(errorMessage);
      return;
    }
  };

  return (
    <div className="signupFormContainer">
      <img
        src="https://atomicloops.com/client/assets/favicon/favicon.ico"
        alt=""
        width={40}
        height={40}
      />
      <h1>Welcome Back</h1>
      <form onSubmit={handleLogin}>
        <input
          type="loginEmail"
          name="loginEmail"
          id="loginEmail"
          placeholder="login email"
          value={loginEmail}
          onChange={(e) => setLoginEmail(e.target.value)}
          required
        />
        <div id="signupPassword">
          <input
            type={showLoginPassword ? 'text' : 'password'}
            name="loginPassword"
            id="loginPassword"
            placeholder="login password"
            value={loginPassword}
            onChange={(e) => setLoginPassword(e.target.value)}
            required
            autoComplete="password"
          />
          {/* eye icon */}
          <i onClick={() => setShowLoginPassword(!showLoginPassword)}>
            {showLoginPassword ? (
              <svg
                width={26}
                height={26}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  stroke="#202123"
                  strokeWidth={0.792}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M12 5C5.636 5 2 12 2 12s3.636 7 10 7 10-7 10-7-3.636-7-10-7Z" />
                  <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                </g>
                <title>Show Password</title>
              </svg>
            ) : (
              <svg
                width={26}
                height={26}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#000"
              >
                <path
                  d="M20 14.834C21.308 13.332 22 12 22 12s-3.636-7-10-7a8.595 8.595 0 0 0-2 .236M12 9a2.995 2.995 0 0 1 3 3M3 3l18 18m-9-6a2.997 2.997 0 0 1-2.959-2.5M4.147 9c-.308.345-.585.682-.828 1C2.453 11.128 2 12 2 12s3.636 7 10 7c.341 0 .675-.02 1-.058"
                  stroke="#202123"
                  strokeWidth={0.768}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <title>Hide Password</title>
              </svg>
            )}
          </i>
        </div>
        <button type="submit" style={{ background: '#0047AB' }}>
          Continue
        </button>
        {errorMessage.trim() !== ' ' && <span>{errorMessage}</span>}
      </form>
    </div>
  );
};

export default SignupForm;
