import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

const NavLinks = ({ svg, link, text, setChatLog }) => {
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleClick = async (text) => {
    if (text === 'Clear Conversations') setChatLog([]);
    if (text === 'Log out') {
      try {
        // localStorage.clear();
        // let logOut = await signOut(auth);
        // dispatch({ type: 'LOGOUT' });
        window.location.replace('/auth/login');
        return;
      } catch (error) {
        console.log('error happen during sign out', error);
      }
    }
  };

  return (
    <Link
      to={link}
      target={link && '_blank'}
      rel="noreferrer"
      style={{ textDecoration: 'none' }}
      onClick={() => handleClick(text)}
    >
      <div className="navPrompt">
        {svg}
        <p style={{color:"black"}}>{text}</p>
      </div>
    </Link>
  );
};

export default NavLinks;
