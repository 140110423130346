import React from 'react';
import BotResponse from './BotResponse';

const IntroSection = () => {
  return (
    <div id="introsection">
      <h1>
        Introducing AtomicGPT
        <BotResponse response=" - The Ultimate AI Assistant" />
      </h1>
      <h2>
      I am a cutting-edge AI-powered assistant that can provide instant answers to any questions that you may have. I am an experts on COMPANY NAME’S website so that you’ll never be left searching for answers again. Whether you need information about Products & Services, Customer Support Information, Legal & Compliance information, Latest News, just ask me a question and I will be able to help you.
      </h2>
      Some of my features are:
      <ul>
        <li>Instant answers to any questions about COMPANY</li>
        <li>Continuous learning for real-time updated information</li>
        <li>Ability to remember and learn from our interaction to better serve your needs</li>
        <li>24/7 availability</li>
        <li>Data security & compliance</li>
        {/* <li></li> */}
      </ul>
      <p>
      If you have any other questions about my abilities, just ask me!
      </p>
    </div>
  );
};

export default IntroSection;
